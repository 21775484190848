// FORM FILEDS
export const MAX_CHARACTER_LENGTH = 50;
export const MAX_LENGTH_NAME = 25;
export const FREE_INPUT_FIELDS_LENGTH = 255;
export const MAX_ADDRESS_LINE2 = 20;

export const TwentyFourHoursInMs = 86400000;
export const REACT_QUERY_ONE_CALL_OPTIONS = {
    staleTime: TwentyFourHoursInMs,
    cacheTime: TwentyFourHoursInMs,
    refetchOnMount: true,
};
