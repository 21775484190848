import { AccountInfo, EndSessionRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { getBaseUrl } from 'utils/url';
import { useMsal } from '@azure/msal-react';

export const anonymousRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_ANONYMOUS_AUTHORITY!,
};

export const pca = new PublicClientApplication({
    auth: {
        clientId: import.meta.env.VITE_APP_AZUREAD_CLIENT_ID!,
        knownAuthorities: import.meta.env.VITE_APP_AZUREAD_KNOWN_AUTHORITIES!.split(' '),
        redirectUri: getBaseUrl(),
    },
});

type useAuthenticationResult = {
    getAccount: () => Partial<AccountInfo> | undefined;
    existsAccount: () => boolean;
    logoutRedirectAnonymous: () => void;
    loginRedirectAnonymous: () => void;
    acquireAccessTokenSilent: () => Promise<string>;
};

const useAuthentication: () => useAuthenticationResult = () => {
    const { instance } = useMsal();

    const existsAccount: () => boolean = () => {
        return !!instance.getAllAccounts()?.length;
    };

    const getAccount: () => AccountInfo | undefined = () => {
        return existsAccount() ? instance.getAllAccounts()[0] : undefined;
    };

    const logoutRedirectAnonymous: () => void = () => {
        const logoutRequest: EndSessionRequest = {
            account: getAccount(),
            authority: import.meta.env.VITE_APP_AZUREAD_ANONYMOUS_AUTHORITY!,
            postLogoutRedirectUri: getBaseUrl(),
        };
        instance.logoutRedirect(logoutRequest);
    };

    const loginRedirectAnonymous: () => void = () => {
        instance.loginRedirect(anonymousRequest);
    };

    const acquireAccessTokenSilent: () => Promise<string> = async () => {
        return (
            await instance.acquireTokenSilent({
                ...anonymousRequest,
                account: getAccount(),
            })
        ).accessToken;
    };

    return {
        getAccount,
        existsAccount,
        logoutRedirectAnonymous,
        loginRedirectAnonymous,
        acquireAccessTokenSilent,
    };
};

export default useAuthentication;
