import { borderRadiusS, colorLighterBlue, colorOnWhiteLightBlue, colorOnWhiteLightGrey } from 'styles/variables';
import styled from 'styled-components';

const IconButton = styled.button`
    background: transparent;
    border: none;
    border-radius: ${borderRadiusS};
    cursor: pointer;
    line-height: 0;
    width: 2rem;
    height: 2rem;
    &:hover {
        background: ${colorOnWhiteLightBlue};
    }

    &:active {
        background: ${colorLighterBlue};
    }
    &:disabled svg {
        fill: ${colorOnWhiteLightGrey};
    }
`;

export default IconButton;
