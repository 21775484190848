import { BuType, UseBUContentResult } from 'types/businessUnit';
import { InteractionStatus } from '@azure/msal-browser';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { getCountryFromUrl } from 'utils/url';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const emptyBUContent: BuType = {
    privacyPolicyLink: '',
    termsOfSalesLink: '',
    availableLanguages: [],
};

const useBUContent = (): UseBUContentResult => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const bu = getCountryFromUrl();
    const buFile = `/bu_${bu}.json`;

    const fetcher = async (): Promise<BuType> => {
        const result = await axios.get<BuType>(buFile).then((resp) => resp.data);

        return result;
    };

    const { data: buContent } = useQuery<BuType>({
        queryKey: [buFile],
        queryFn: fetcher,
        ...REACT_QUERY_ONE_CALL_OPTIONS,
        enabled: isAuthenticated && inProgress === InteractionStatus.None,
    });

    return {
        buContent: buContent ?? emptyBUContent,
    };
};

export default useBUContent;
