// Primary
export const colorPrimaryMerBlue = '#0000c2';

// Secondary
export const colorSecondaryRed = '#e51c5c';

// Neutrals
export const colorNeutralsBlack = '#333333';
export const colorNeutralsWhite = '#ffffff';

// colors
export const grey = '#767676';
export const lightGrey = '#dcdfe3';

export const colorDarkBlue = '#000d82';
export const colorDarkerBlue = '#00044a';
export const colorBlue = '#b1d4f8';
export const colorLighterBlue = '#deedfd';
export const colorCyanBlue = '#85BCF5';

export const colorLightGreen = '#55b938';

// On white
export const colorOnWhiteDarkGrey = '#666666';
export const colorOnWhiteMediumGrey = '#999999';
export const colorOnWhiteLightGrey = '#cccccc';
export const colorOnWhiteLighterGrey = '#f9f9f9';
export const colorOnWhiteLightBlue = '#f3f8ff';

// Components
export const borderColorBlue = colorBlue;

// Other
export const errorColor = colorSecondaryRed;
export const successColor = colorLightGreen;
export const linkHoverBackgound = colorLighterBlue;

export const shadowCircular = '0px 0px 2px 2px #0000000d';
export const shadowBlue = '0px 0px 4px 2px #b1d4f8';
export const shadowBlueSharp = '0 0 0 4px #b1d4f8';
export const shadowRedSharp = '0 0 0 1px #e51c5c';
export const menuBoxShadow = '0px 16px 24px 0px rgba(0, 0, 0, 0.08)';

export const spaceXxxs = '0.125rem'; // 2px
export const spaceXxs = '0.25rem'; // 4px
export const spaceXs = '0.5rem'; // 8px
export const spaceS = '1rem'; // 16px
export const spaceM = '1.5rem'; // 24px
export const spaceL = '2rem'; // 32px
export const spaceXl = '3rem'; // 48px
export const spaceXxl = '5rem'; // 80px

// Screen Widths
export const screenWidthMini = '500px';
export const screenWidthSmall = '768px';
export const screenWidthMedium = '1024px';
export const screenWidthLarge = '1200px';
export const maxWidthContent = '1200px';
export const maxWidthContentPublicPage = '920px';

export const readingWidth = '600px';

// Form inputs
export const inputWidth = '35.125rem'; // 562px

// Content
export const headerHeight = '4rem';
export const defaultButtonHeight = '3rem';
export const smallButtonHeight = '2rem';

// Icon
export const iconSizeS = '1rem';
export const iconSize = '1.5rem';
export const iconSizeM = '3.5rem'; // 56px

// Border radius
export const borderRadiusXs = '0.125rem'; // 2px
export const borderRadiusS = '0.25rem'; // 4px
export const borderRadiusM = '0.5rem'; // 8px

// Font Weights
export const font = {
    weight: {
        light: 300,
        regular: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
    size: {
        mainHeadline: '2.2rem', // 35.2px
        fieldLabel: '0.875rem', // 14px
        xxl: '2rem', // 32px
        xl: '1.5rem', // 24px
        l: '1.25rem', // 20px
        m: '1rem', // 16px
        s: '0.875rem', // 14px
        xs: '0.75rem', // 12px
        xxs: '0.625rem', // 10px
    },
    lineHeight: {
        xxxl: '3rem', // 48px
        xxl: '2.5rem', // 40px
        xl: '2rem', // 32px
        l: '1.5rem', // 24px
        m: '1.25rem', // 20px
        s: '1rem', // 16px
        xs: '0.875rem', // 14px
    },
    body: "'SharpSans Medium', sans-serif",
    bold: "'SharpSans Bold', sans-serif",
    color: {
        default: colorNeutralsBlack,
        grey: colorOnWhiteDarkGrey,
    },
};

// Transitions
export const transitionSnappy = '0.1s ease-in 25ms';
