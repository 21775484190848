import { Outlet } from 'react-router-dom';
import GlobalStyle from 'styles/globalStyle';
import PageLayout from 'components/layout/PageLayout';
import PublicRoute from 'components/PublicRoute';

function RouteWrapper(): JSX.Element {
    return (
        <>
            <GlobalStyle />
            <PublicRoute>
                <PageLayout>
                    <Outlet />
                </PageLayout>
            </PublicRoute>
        </>
    );
}

export default RouteWrapper;
