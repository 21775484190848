export enum PATH {
    PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS = '/subscriptions/:subscriptionId/delivery-address',
    PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS = '/subscriptions/delivery-address/:status',
    PUBLIC_SUBSCRIPTIONS_WILDCARD = '/subscriptions/*',

    ERROR = '/error',
    TEMPORARY_ERROR = '/temporaryError',
    CRITICAL_ERROR = '/criticalError',

    WILDCARD = '/*',
}
