import { colorNeutralsWhite, screenWidthSmall } from 'styles/variables';
import { useCallback, useEffect, useState } from 'react';
import LanguageSelector from 'components/forms/LanguageSelector';
import MerLogo from 'components/visuals/MerLogo';
import styled from 'styled-components';

const Container = styled.header`
    grid-area: header;
    background-color: ${colorNeutralsWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9000;
    transition: box-shadow 0.67s ease-in-out;

    @media screen and (min-width: ${screenWidthSmall}) {
        z-index: 7000;
        &&.pinned {
            box-shadow: 0 1px 1px rgba(32, 32, 32, 0.1);
        }
    }
`;

enum HeaderState {
    DEFAULT = 'default',
    PINNED = 'pinned',
}

function Header(): JSX.Element {
    const [headerState, setHeaderState] = useState(HeaderState.DEFAULT);

    const handleScroll = useCallback(() => {
        const offset = window.scrollY;
        if (offset > 10 && headerState !== HeaderState.PINNED) {
            setHeaderState(HeaderState.PINNED);
        } else {
            setHeaderState(HeaderState.DEFAULT);
        }
    }, [setHeaderState, headerState]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Container className={headerState} data-testid="header">
            <MerLogo size="64px" />
            <LanguageSelector />
        </Container>
    );
}

export default Header;
